// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-pcpr-js": () => import("./../../../src/templates/about-PCPR.js" /* webpackChunkName: "component---src-templates-about-pcpr-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-cooperation-js": () => import("./../../../src/templates/cooperation.js" /* webpackChunkName: "component---src-templates-cooperation-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-list-js": () => import("./../../../src/templates/news-list.js" /* webpackChunkName: "component---src-templates-news-list-js" */),
  "component---src-templates-projects-js": () => import("./../../../src/templates/projects.js" /* webpackChunkName: "component---src-templates-projects-js" */),
  "component---src-templates-task-of-pcpr-js": () => import("./../../../src/templates/task-of-PCPR.js" /* webpackChunkName: "component---src-templates-task-of-pcpr-js" */)
}

