import React, { useState } from "react";

export const introContext = React.createContext();

const Provider = ({ children }) => {
  const [visited, setVisited] = useState(false);

  return (
    <introContext.Provider
      value={{
        visited,
        disableIntro: () => setVisited(true),
      }}
    >
      {children}
    </introContext.Provider>
  );
};

export default ({ element }) => <Provider>{element}</Provider>;
